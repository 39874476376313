'use client';
import { ErrorPageContent } from '@components/Errors/ErrorPageContent';
import { captureException } from '@sentry/nextjs';
import Link from 'next/link';
import { DEFAULT_LOCALE } from 'utils';

const AppError = ({ error }) => {
  if (error) {
    captureException(error);
  }

  return (
    <html lang={DEFAULT_LOCALE}>
      <body>
        <ErrorPageContent
          title="Something went wrong"
          description={
            <>
              Please{' '}
              <Link className="text-blue" href="/">
                try again
              </Link>{' '}
              later, or check the Asset Store&apos;s{' '}
              <Link
                className="text-blue"
                href="https://status.unity.com/"
                target="_blank"
              >
                Service Status
              </Link>
            </>
          }
        />
      </body>
    </html>
  );
};

export default AppError;
